<template>
  <div class="userLastCon" v-if="creator">
    <div class="userLastConContent" v-if="isOnline">{{ $locale["online"] }}</div>
    <div class="userLastConContent" v-else-if="creator.lastConnection">
      <span>{{ $locale["last_seen"] }}</span>
      <span lower>{{ getTimeLapse({ date: creator.lastConnection }) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["creator"],
  computed: {
    isOnline: function() {
      return this.creator.online;
    },
  },
};
</script>

<style lang="scss" scoped>
.userLastCon {
  font-size: 90%;
  .userLastConContent {
    display: flex;
    gap: $mpadding/2;
  }
}
</style>
